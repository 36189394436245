<template>
  <div>
    <div class="mb-2">
      <a
        href="https://www.loom.com/share/28f7198cc0d54866bec64bcf81daa9c6"
        target="_blank"
        >See video about how to use promotion links</a
      >
    </div>
    <div v-if="campaigns.length === 0" class="text-center">
      <p>You have no active campaigns</p>

      <v-btn color="success" large :to="{ name: 'campaigns' }">
        See new campaigns
      </v-btn>
      <img
        src="@/assets/rocketInfl.svg"
        alt="rocket"
        class="default-img mt-150"
      />
    </div>

    <v-stepper v-else v-model="step">
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-3">
          <div class="mb-4">
            <div class="headline pt-2 pb-2">
              Choose campaign for promotion
            </div>
            <div class="subtitle-1 light-grey">
              Find your active campaign links, which are required for us to track your
              clicks and sales.
              <a
                href="https://intercom.help/Make-influence-com/da/articles/6243608-hvad-er-et-promoveringslink-og-sadan-laver-du-dine-promoveringslinks"
                target="_blank"
                >Read more about campaign links</a
              >
            </div>
          </div>

          <v-container class="p-0" fluid grid-list-lg>
            <v-layout wrap>
              <v-flex
                v-for="campaign in campaigns"
                :key="campaign.uuid"
                md4
                sm6
              >
                <v-card flat class="bordered flex-card" height="100%">
                  <campaign-image
                    :src="campaign.image_url"
                    :height="185"
                  ></campaign-image>
                  <v-card-text class="grow">
                    <div class="mb-3">
                      <div class="title primary--text mb-0">
                        {{ campaign.name }}
                      </div>
                      <div class="subheading mb-0 greytxt--text">
                        {{ campaign.business.display_name }}
                      </div>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      block
                      color="primary"
                      @click="
                        selectedCampaign = campaign;
                        nextStep(2);
                      "
                    >
                      Promote campaign
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="2" class="pa-3">
          <div class="mb-4">
            <div class="headline pt-2 pb-2">Choose media</div>
          </div>

          <v-container fluid grid-list-lg class="p-0">
            <v-layout wrap>
              <v-flex
                v-for="influencerChannel in activeChannels"
                :key="influencerChannel.uuid"
                lg4
                md6
                xs12
                class="flex-card cursor-pointer"
                height="100%"
              >
                <v-card flat class="bordered">
                  <div class="p-3 text-center">
                    <v-avatar class="elevation-1" :size="150">
                      <img :src="influencerChannel.profile_image_url" />
                    </v-avatar>
                  </div>
                  <v-card-text class="pa-0">
                    <v-list two-line class="pa-0">
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon>{{ influencerChannel.channel.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ influencerChannel.username }}
                          </v-list-item-title>
                          <v-list-item-subtitle>{{
                            influencerChannel.channel.display_name
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      block
                      color="primary"
                      @click="
                        selectedInfluencerChannel = influencerChannel;
                        nextStep(3);
                      "
                    >
                      Choose media
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>

          <div class="text-center mt-3">
            <v-btn text @click="previousStep(1)">
              Back
            </v-btn>
          </div>
        </v-stepper-content>
        <v-stepper-content step="3" class="pa-3">
          <div class="mb-4">
            <div class="headline pt-2 pb-2">
              Create your own link (optional)
            </div>
            <div class="subtitle-1 light-grey">
              In this campaign you have the option to decide, where your followers
              end up on the website.
              <a
                href="https://intercom.help/Make-influence-com/da/articles/6243608-hvad-er-et-promoveringslink-og-sadan-laver-du-dine-promoveringslinks"
                target="_blank"
                >Read more</a
              >
            </div>
          </div>

          <template v-if="selectedCampaign !== null">
            <v-layout row>
              <v-flex sm6 offset-sm3>
                <p>
                  Choose link from website
                  <a
                    class="font-weight-bold"
                    :href="'http://' + campaignDomain"
                    target="_blank"
                    v-text="campaignDomain"
                  ></a>
                </p>

                <v-text-field
                  v-model="selectedCampaign.deepLink"
                  label="Insert link"
                  v-validate="'url:true|campaign_domain_match'"
                  data-vv-name="Link"
                  :error-messages="errors.collect('Link')"
                  data-vv-validate-on="blur"
                  clearable
                ></v-text-field>
              </v-flex>
            </v-layout>

            <div class="text-center mt-3">
              <v-btn text @click="previousStep(2)">
                Back
              </v-btn>
              <v-btn
                color="primary"
                :outlined="!selectedCampaign.deepLink"
                @click="nextStep(4)"
                :disabled="!deepLinkValid"
              >
                {{ !selectedCampaign.deepLink ? "Skip" : "Continue" }}
              </v-btn>
            </div>
          </template>
        </v-stepper-content>
        <v-stepper-content step="4" class="pa-3">
          <div v-if="selectedCampaign" class="mb-4">
            <div class="headline pt-2 pb-2">
              Link for
              <span class="primary--text">{{
                selectedCampaign.business.display_name
              }}</span>
              is ready 🚀🥳
            </div>
            <div class="subtitle-1 light-grey">
              Don't forget to use the campaign link every time you promote
              <b>{{ selectedCampaign.name }}</b>
            </div>
          </div>

          <v-layout wrap>
            <!--TODO: Maybe create a box with "Link works with" "Campaign card" and "Channel card"-->

            <v-flex sm6 offset-sm3>
              <v-form>
                <v-select
                  v-model="selectedDomain"
                  :items="trackingDomains"
                  label="Link domain"
                  placeholder="Domain"
                  dense
                  outlined
                ></v-select>

                <v-textarea
                  v-if="step === 4"
                  label="Unique promotion link"
                  class="mb-3"
                  outlined
                  :hint="
                    canAutoCopy
                      ? 'Click the icon for automatic copying' 
                      : 'Click in the filed to copy'
                  "
                  persistent-hint
                  auto-grow
                  rows="3"
                  ref="trackingLinkInput"
                  :id="trackingLinkInputId"
                  :value="trackingLinkFinal"
                >
                  <template slot="append">
                    <v-icon @click="copyTrackingLink()">fal fa-copy</v-icon>
                  </template>
                </v-textarea>

                <div v-if="step === 4" class="mt-4 mb-3">
                  <a
                     href="https://intercom.help/Make-influence-com/da/articles/6243527-hvad-er-unikke-hashtags-og-hvorfor-skal-jeg-bruge-dem"
                     target="_blank"
                  >
                    Read more about promotion hashtags
                  </a>
                </div>

                <v-textarea
                   v-if="step === 4"
                   label="Unique promotion hashtag"
                   outlined
                   :hint="
                    canAutoCopy
                      ? 'Click the icon for automatic copying' 
                      : 'Click in the filed to copy'
                  "
                   persistent-hint
                   auto-grow
                   rows="3"
                   ref="trackingHashtagInput"
                   :id="trackingHashtagInputId"
                   :value="trackingHashtag"
                >
                  <template slot="append">
                    <v-icon @click="copyTrackingHashtag()">fal fa-copy</v-icon>
                  </template>
                </v-textarea>
              </v-form>
            </v-flex>
          </v-layout>

          <div class="text-center mt-3">
            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              text
              @click="previousStep(3)"
            >
              Back
            </v-btn>

            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              color="primary"
              outlined
              @click="reset()"
            >
              Promote new campaign
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import copy_clipboard from "@/helpers/mixins/copy_clipboard";
import CampaignImage from "../../images/CampaignImage";

export default {
  components: { CampaignImage },
  mixins: [copy_clipboard],
  data: () => ({
    step: 1,
    campaigns: [],
    selectedInfluencerChannel: null,
    selectedCampaign: null,
    deepLinkValid: true,
    trackingLink: "",
    trackingHashtag: "",
    selectedDomain: null,
    trackingDomains: [
      "system.makeinfluence.com",
      "klik-for.link",
      "tryk-for.link",
      "click-for.link",
      "visitt.link"
    ]
  }),
  computed: {
    ...mapState("settings", ["settings"]),
    ...mapState("core/auth", ["user"]),
    ...mapGetters("core/auth", ["activeChannels"]),
    trackingLinkFinal() {
      if(! this.selectedDomain || ! this.trackingLink) {
        return this.trackingLink;
      }
      let domain = (new URL(this.trackingLink));

      let trackingLink = this.cloneDeep(this.trackingLink);

      return trackingLink.replace(domain.hostname, this.selectedDomain);
    },
    campaignDomain() {
      if (this.selectedCampaign) {
        return this.selectedCampaign.url
          .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
          .replace("http://", "")
          .split(/[/?#]/)[0];
      }

      return "";
    },
    trackingLinkInputId() {
      return this._uid + "link";
    },
    trackingHashtagInputId() {
      return this._uid + "hashtag";
    },
    canAutoCopy() {
      return (
        document.queryCommandSupported && document.queryCommandSupported("copy")
      );
    }
  },
  watch: {
    selectedCampaign: {
      deep: true,
      handler() {
        this.deepLinkValid = true;
        this.$validator.reset();

        this.$nextTick(() => {
          if (this.selectedCampaign && this.selectedCampaign.deepLink) {
            this.$validator.validateAll().then(value => {
              this.deepLinkValid = !!value;
            });
          }
        });
      }
    },
    step(newValue, oldValue) {
      // Remove selected campaign if you press back
      if (newValue === 1 && oldValue > 1 && this.$route.query.campaign) {
        let query = Object.assign({}, this.$route.query);
        delete query.campaign;
        this.$router.replace({ query });
      }

      if (newValue === 4) {
        this.getTrackingLink();
      }
    }
  },
  methods: {
    ...mapActions("loading", ["setPageLoading"]),
    ...mapActions("core/campaigns", ["loadCampaigns"]),
    ...mapActions("core", ["createTrackingLink"]),
    nextStep(step) {
      window.scrollTo(0, 0);

      if (step === 2 && this.activeChannels.length <= 1) {
        step = 3;
      }

      if (step === 3 && !this.selectedCampaign.allow_deep_linking) {
        step = 4;
      }

      this.step = step;
    },
    previousStep(step) {
      window.scrollTo(0, 0);

      if (step === 3 && !this.selectedCampaign.allow_deep_linking) {
        step = 2;
      }

      if (step === 2 && this.activeChannels.length <= 1) {
        step = 1;
      }

      this.step = step;
    },
    reset() {
      this.trackingLink = "";
      this.trackingHashtag = "";
      this.selectedCampaign = null;
      this.selectedInfluencerChannel = this.activeChannels[0];
      this.step = 1;
    },
    getTrackingLink() {
      let params = {
        campaign_uuid: this.selectedCampaign.uuid,
        influencer_channel_uuid: this.selectedInfluencerChannel.uuid
      };

      if (
        this.selectedCampaign &&
        this.selectedCampaign.allow_deep_linking &&
        this.selectedCampaign.deepLink
      ) {
        params.link = this.selectedCampaign.deepLink;
      }

      this.createTrackingLink(params)
        .then(link => {
          this.trackingLink = link.url;
          this.trackingHashtag = link.hashtag;
        })
        .catch(() => {
          this.setSnackError("Something went wrong");
        });
    },
    copyTrackingLink() {
      if (this.canAutoCopy) {
        this.copyElementText(document.getElementById(this.trackingLinkInputId));
      }
    },
    copyTrackingHashtag() {
      if (this.canAutoCopy) {
        this.copyElementText(document.getElementById(this.trackingHashtagInputId));
      }
    }
  },
  created() {
    this.setPageLoading(true);
    this.reset();

    let params = {
      influencer_uuid: this.user.influencer.uuid,
      page: 1,
      limit: 100,
      active_tracking_campaigns: true
    };

    this.loadCampaigns(params)
      .then(campaigns => {
        this.campaigns = campaigns.response;
        // If selected with route param
        if (this.$route.query && this.$route.query.campaign) {
          let exists = this.campaigns.filter(
            item => item.uuid === this.$route.query.campaign
          );

          if (exists.length > 0) {
            this.selectedCampaign = exists[0];
            this.nextStep(2);
          }
        }

        this.setPageLoading(false);
      })
      .catch(() => {
        this.setSnackError("Something went wrong");
      });

    let _this = this;

    // TODO: Move to mixin
    // Init deep link validation
    this.$validator.extend("campaign_domain_match", {
      getMessage() {
        return "Link does not match campaign website";
      },
      validate(value) {
        let deepLink = value
          .toLowerCase()
          .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
          .replace("http://", "")
          .split(/[/?#]/)[0];
        let campaignUrl = _this.selectedCampaign.url
          .toLowerCase()
          .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
          .replace("http://", "")
          .split(/[/?#]/)[0];

        return deepLink === campaignUrl;
      }
    });
  }
};
</script>
